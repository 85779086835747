import { getConfiguration } from '../../../shared/hooks/user-session.hook';
import { defaultSlug } from '../../../shared/mocks/configuration.mock';
import { IConfiguration } from '../../../shared/interfaces';
import { useNavigate, useParams } from 'react-router-dom';
import domainCompanyMap from '../../../config/domainCompanyMap';
import { useEffect } from 'react';

interface RedirectHomeProps {
   urlShort?: boolean;
}

export function RedirectHome({ urlShort }: RedirectHomeProps) {
   // configuration
   const navigate = useNavigate();
   const { slugCompany } = useParams();
   const domain = window.location.hostname;
   const configurationLS: IConfiguration | null = getConfiguration();

   // effects
   useEffect(() => {
      const expectedSlugCompany = domainCompanyMap[domain];

      if (expectedSlugCompany) {
         navigate(`/${expectedSlugCompany}/home`);
         return;
      }

      if (urlShort) {
         navigate(`/${slugCompany}/home`);
         return;
      }

      if (configurationLS) {
         navigate(`/${configurationLS.slugCompany}/home`);
         return;
      }

      navigate(`/${defaultSlug}/home`);
   }, []);

   return <></>;
}
