import { BrowserRouter } from 'react-router-dom';
import { store } from './shared/redux/store';
import './shared/languages/language.service';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import 'classlist-polyfill';
import App from './App';
import './global.scss';
import 'dom4';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
   //<React.StrictMode>
      <Provider store={store}>
         <BrowserRouter>
            <App />
         </BrowserRouter>
      </Provider>
   // </React.StrictMode>
);
